main {
  margin-top: 80px
}
a {
  text-decoration: none;

}

.link-no-style {
  text-decoration: none;
}
