body {
  margin: 0;
  font-family: 'Teko', 'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171717 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}


